<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="isShow"
      width="630px"
      :before-close="handleClose"
      custom-class="add-user"
      @open="openFn"
    >
      <div class="content">
        <el-form
          ref="form"
          :model="formData"
          :rules="rules"
          label-width="150px"
        >
          <el-form-item label="手机号码" prop="telephone">
            <el-input
              v-model="formData.telephone"
              @input="formatData('telephone')"
              maxlength="11"
              @change="telephoneChang"
            ></el-input>
          </el-form-item>
		  <el-form-item label="验证码" prop="smsCode" v-if="!isEdit">
            <div class="send-code">
              <el-input v-model="formData.smsCode"></el-input>
              <el-button @click="sendCode">{{ codeBtn }}</el-button>
            </div>
          </el-form-item>
          <el-form-item label="登录密码" prop="password" v-if="!isEdit">
            <el-input
              v-model="formData.password"
              show-password
              maxlength="18"
            ></el-input>
          </el-form-item>
          <el-form-item label="员工姓名" prop="realName">
            <el-input v-model="formData.realName" maxlength="22"></el-input>
          </el-form-item>
          <el-form-item label="岗位" prop="title">
            <el-input
              placeholder="请填写岗位名称"
              v-model="formData.title"
              maxlength="10"
            ></el-input>
          </el-form-item>
          <el-form-item label="员工性别" prop="sex" class="sex">
            <el-radio-group v-model="formData.sex">
              <el-radio :label="0">男</el-radio>
              <el-radio :label="1">女</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="邮箱地址" prop="email">
            <el-input v-model="formData.email"></el-input>
          </el-form-item>
        </el-form>
        <div class="btn-wrap">
          <el-button @click="handleClose">取消</el-button>
          <el-button @click="submit" class="hy-btn" :loading="submiting"
            >确定</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { reqAddUser, reqUserDetail, reqEditUser, reqSendCode } from "@/api/hy";
import Vue from "vue";
import * as types from "@/store/action-types";
import md5 from "js-md5";
export default {
  data() {
    const validateName = (rule, value, callback) => {
      let validate = /^[\u4e00-\u9fa5]{2,20}$/;
      if (!validate.test(value)) {
        return callback(new Error("员工姓名只能输入2-20位汉字"));
      } else {
        return callback();
      }
    };
    const validatePassword = (rule, value, callback) => {
      let validate = /^[0-9|0-9a-zA-Z|a-zA-Z]{6,12}$/;
      if (!validate.test(value)) {
        return callback(new Error("密码只能6-12位数字/字母/数字字母组合"));
      } else {
        return callback();
      }
    };
    return {
      title: "添加员工",
      formData: {
        telephone: "",
        password: "",
        realName: "",
        sex: 0,
        email: "",
        smsCode: "",
        title:''
      },
      rules: {
        telephone: [
          { required: true, message: "请填写手机号", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入登录密码", trigger: "blur" },
          { validator: validatePassword, trigger: "blur" },
        ],
        realName: [
          { required: true, message: "请输入员工姓名", trigger: "blur" },
          { validator: validateName, trigger: "blur" },
        ],
        smsCode: [
          { required: true, message: "请填写手机验证码", trigger: "blur" },
        ],
        title: [{ required: true, message: "请填写岗位名称", trigger: "blur" }],
        email: [{ required: true, message: "请填写邮箱地址", trigger: "blur" }],
      },
      codeBtn: "获取验证码",
      sendCodeEd: false,
      submiting: false,
      isEdit: false,
    };
  },
  props: {
    isShow: {
      type: Boolean,
    },
    data: {
      type: Object,
    },
  },
  methods: {
    sendCode() {
      if (this.sendCodeEd) return;
      reqSendCode({ phone: this.formData.telephone })
        .then((ret) => {
          if (ret.code == 0) {
            this.$message({
              type: "success",
              message: "发送成功",
            });
            this.sendCodeEd = true;
            let t = 60;
            let time = setInterval(() => {
              this.codeBtn = `${t}秒后发送`;
              t--;
              if (t <= 0) {
                clearInterval(time);
                this.codeBtn = "获取验证码";
                this.sendCodeEd = false;
              }
            }, 1000);
          } else {
            this.$message({
              type: "error",
              message: ret.message,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    openFn() {
      if (this.data.userId) {
        this.isEdit = true;
        this.title = "编辑员工";
        reqUserDetail({ userId: this.data.userId }).then((ret) => {
          let data = ret.data;
          this.formData = {
            telephone: data.telephone,
            realName: data.realName,
            sex: data.sex,
            email: data.email,
            userType: data.userType,
            title:data.title
          };
        });
      } else {
        this.isEdit = false;
        this.title = "添加员工";
      }
    },
    handleClose() {
      this.formData.telephone = "";
      this.formData.password = "";
      this.formData.realName = "";
      this.formData.sex = 0;
      this.formData.email = "";
      this.formData.smsCode = "";
      this.formData.title = "";
      console.log(11111111)
      this.$emit("close");
    },
    validatePhoneNum(phoneNum) {
      var str = phoneNum;
      var myreg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
      if (!myreg.test(str)) {
        this.$message({
          type: "error",
          message: "请填写正确的手机号码",
        });
      } else {
        return;
      }
    },
    telephoneChang(e) {
      this.validatePhoneNum(e);
    },
    formatData(name) {
      let item = {
        telephone: this.formData[name].match(/^\d*/g)[0] || "",
      };
      this.formData[name] = item[name];
    },
    submit() {
      console.log(this.formData.email)
      this.$refs.form.validate((valid) => {
        let password = this.formData.password
        if (valid) {
          // if (this.formData.password) {
          //   this.formData.password = md5(this.formData.password);
          // }
          // let data = {
          //   ...this.formData,
          //   createBy: Vue.ls.get(types.userId),
          // };
          console.log(this.formData)
          let data = {
            createBy: Vue.ls.get(types.userId),
            telephone: this.formData.telephone,
            password:this.formData.password?md5(this.formData.password):"",
            realName: this.formData.realName,
            sex: this.formData.sex,
            email: this.formData.email,
            smsCode: this.formData.smsCode,
            title:this.formData.title,
			userType:this.formData.userType?this.formData.userType:"3"
          }
          if (this.isEdit) {
            data.userId = this.data.userId;
          }
          if (this.submiting) return;
          this.submiting = true;
          let fn = this.isEdit ? reqEditUser(data) : reqAddUser(data);
          fn.then((ret) => {
            if (ret.code == 0) {
              this.formData.telephone = "";
              this.formData.password = "";
              this.formData.realName = "";
              this.formData.sex = 0;
              this.formData.email = "";
              this.formData.smsCode = "";
              this.formData.title = "";

              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.handleClose();
              this.refreshList();
            } else {
            
              this.$message({
                type: "error",
                message: ret.message,
              });
            }
            this.submiting = false;
          }).catch((err) => {
            this.formData.password=password
            console.log(err);
            this.submiting = false;
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    refreshList() {
      this.$parent.search.pageNum = 1;
      this.$parent.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-wrap {
  text-align: right;
}
.send-code {
  display: flex;
  align-items: center;
  border-radius: 2px;
  .el-input{
	  width: 240px;
  }
  &::v-deep {
    .el-input,
    .el-input__inner,
    .el-button {
      border-radius: 0;
      height: 44px;
    }
    .el-input__inner {
      border-right: none;
    }
    .el-input__inner {
      width: 240px;
    }
  }
}
::v-deep {
  .add-user {
    border-radius: 10px !important;
  }
  .el-dialog__header {
    padding: 19px 25px;
    background: #f8f8f8;
    border-radius: 10px 10px 0 0 !important;
  }
  .el-dialog__title {
    font-size: 16px;
  }
  .el-form {
    width: 500px;
  }
  .hy-btn {
    background: linear-gradient(90deg, #2079fb, #4cb2fc);
    color: white;
  }
  .el-form-item__content {
    display: flex;
    align-items: center;
    height: 44px;
  }
}
</style>
